@import '../../styles/customMediaQueries.css';

/* 404 page */
.page {
  background: var(--primary-eaf-6-f-6, #EAF6F6);
}
.root {
  /* Expand to the available space */
  flex-grow: 1;
  padding-top: 140px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewLargeScreenMax) {
    width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.content {
  width: 879px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 59px;
}

.image {

}

.description {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.description p {
  color: var(--primary-1-b-242-d, #1B242D);
  text-align: center;

  /* H5 */
  font-family: Clash Display;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 30px */
}

.button {
  display: flex;
  height: 53px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: var(--primary-227667, #227667);
  color: #fff;
}
