@import '../../styles/customMediaQueries.css';

.sectionbody {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(37.5px);
  position: absolute;

  @media (--viewLargeScreenMax) {
    display: none;
  }
}

.sectionsidebar {
  display: flex;
  width: 402px;
  padding: 130px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-shrink: 0;
  position: absolute;
  right: 0px;
  height: 100vh;
  border-radius: 30px 0px 0px 30px;
  background: var(--primary-eaf-6-f-6, #eaf6f6);

  @media (--viewLargeScreenMax) {
    width: 100%;
    top: 0px;
    border-radius: 0px 0px 0px 0px;
  }
}

.sectioncategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.categoryItem {
  display: flex;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--primary-1-b-242-d, #1b242d);
  
  /* H4 */
  font-family: Clash Display;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 36px */
  
  &:hover {
    text-decoration: none;
    color: var(--colorSuccessDark);
  }
}

.sectionpages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  align-self: stretch;
}

.pageItem {
  display: flex;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--primary-1-b-242-d, #1b242d);
  
  /* H6 */
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
  cursor: pointer;
  
  &:hover {
    text-decoration: none;
    color: var(--colorSuccessDark);
  }
}

.menubtn {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  cursor: pointer;
}

.iconBtnClass {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.closeIcon {
  opacity: 1;
  transition: 0.1s;

  &:hover {
    opacity: 0.8;
  }
}