.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
  /* justify-content: center; */
}
