@import '../../styles/customMediaQueries.css';

.menuContainer {
  display: flex;
  width: 250px;
  height: 692px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;

  border-radius: 0px 20px 20px 0px;
  background: var(--primary-eaf-6-f-6, #eaf6f6);

  @media (--viewLargeScreenMax) {
    display: none;
  }

  @media (--viewXXLargeScreenMin) {
    border-radius: 20px;
  }
}

.menuitemactive {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 99px;
  background: var(--primary-ffffff, #fff);

  color: var(--primary-1-b-242-d, #1b242d);
  text-align: center;

  /* P16 - Standard */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  cursor: pointer;
}

.menuitem {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 99px;

  color: var(--primary-1-b-242-d, #1b242d);
  text-align: center;

  /* P16 - Standard */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.45);
  }
}
